<template>
  <vue-select
    v-model="model"
    class="base-select"
    :class="selectClass"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #no-options> Nenhuma opção encontrada </template>
  </vue-select>
</template>

<script>
import VueSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'BaseSelect',
  components: {
    VueSelect
  },
  props: {
    value: {
      type: [Number, String, Object],
      default: null
    },
    validation: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    model: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    selectClass() {
      return {
        'is-invalid': this.validation.$dirty && this.validation.$invalid
      }
    }
  }
}
</script>

<style lang="scss">
.base-select {
  &.vs__dropdown-toggle {
    height: calc(1.5em + 0.75rem + 2px) !important;
  }

  &.is-invalid {
    .vs__dropdown-toggle {
      border-color: #dc3545;
    }
  }

  .vs__dropdown-toggle {
    height: 38px;
  }
}
</style>
